@import '../../styles/variable.scss';

.home{
    text-align: left;
    margin: auto;
    font-size: 1.2rem;
    background: white;
    border-radius: 10px;
    // box-shadow: 0 0 30px rgba(black, 0.1);
    padding: 50px;

    @media(max-width: 700px){
        padding: 10px;
    }

    h1 {
        margin-bottom: 20px;
        text-align: center;
        font-size: 2rem;
    }    
}

.landing {
    height: 100vh;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}