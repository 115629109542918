@import '../../../styles/variable.scss'

.component.branding
    margin-top: 50px
    text-align: center
    font-size: 1.5rem
    margin-bottom: 10px
    .name
        text-align: center
        font-size: 8rem
        font-weight: 500
        opacity: 1
        @media(max-width: 750px)
            font-size: 3.3rem
        @media (max-width: 810px)and (min-width: 700px)
            font-size: 6rem
    .real-name
        font-family: $sans
        font-weight: 900
        font-size: 1.2rem
