@import './variable.scss';

.fav-display {
    .display-drawer {
        box-shadow: 0px 0px 30px -15px rgba(0,0,0,0.3);
        display: block;
        z-index: 999;
        position: fixed;
        top: 0px;
        padding: 20px;
        background: rgba(white, 0.3);
        backdrop-filter: blur(20px);
        transition: .2s position ease-in-out;
        text-align: center;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        width: 300px;
        height: 500px;
        overflow-y: scroll;
        img {
            width: 60%;
            max-width: 200px;
            border: 2px solid rgba(black, 0.1);
            border-radius: 10px;
        }
        .check-it {
            display: inline-block;
            cursor: pointer;
            margin: 20px;
            text-transform: capitalize;
            border-radius: 10px;
            padding: 8px 30px;
            text-align: center;
            font-size: 1rem;
            font-weight: 600;
            user-select: none;
            background: rgba(0,0,0,1);
            color: white;
            margin-bottom: 50px;
            &:hover{
                background: rgba(0,0,0,0.8);
            }
            &:active{
                background: rgba(0,0,0,0.7);
            }
        }
        .intro {
            font-weight: 500;
            opacity: 0.8;
            font-size: 1.1rem;
            padding: 10px;
            margin: 0px;
            line-height: 2rem;
            text-align: center;
        }
        //.close {
        //    transform: scale(1.1);
        //    cursor: pointer;
        //    user-select: none;
        //    position: absolute;
        //    top: -40px;
        //    left: 20px;
        //    display: inline-block;
        //    background: rgba(0,0,0,0.9);
        //    padding: 8px 12px 7px;
        //    font-weight: 600;
        //    color: white;
        //    border-radius: 100px;
        //    text-transform: uppercase;
        //    letter-spacing: 1.2px;
        //    font-size: 0.7rem;
        //    text-align: center;
        //    line-height: 9px;
        //    opacity: 0.8;
        //    margin: auto;
        //    &:hover{
        //        opacity: 1;
        //    }
        //}
        .name {
            text-align: center;
            font-weight: 900;
            font-size: 2rem;
            margin-bottom: 20px;
            text-transform: capitalize;
            color: $gold;
        }
        .frame {
            overflow: hidden;
            border-radius: 10px;
            position: relative;
            height: 50px;
            width: 50px;
            img {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                text-align: center;
                height: 100%;
            }
        }
        &.pin-true {
            box-shadow: 0px 0px 40px 10px rgba(0,0,0,0.1);
        }
        &.false {
            transition: .2s all ease-in-out;
            bottom: -500px;
            right: 0;
            left: 0;
        }
        &.true {
            transition: .2s all ease-in-out;
            bottom: 0;
            right: 0;
            left: 0;
        }
    }
}
