
.recent-component {
    text-align: center;
    padding: 30px;
    // background: rgba(159, 145, 145, 0.1);
    // background: -webkit-linear-gradient(20deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    border-radius: 10px;
    font-size: 1.2rem;
    color: black;
    opacity: 0.9;
    font-weight: 400;
    margin: 0px 5px;
    .title-component {
        color: white !important;
    }
    .list {
        line-height: 2rem;
    }
    a {
        font-weight: 800;
        text-decoration: none;
        color: black;
        &:hover {
            font-style: italic;
        }
    }
}
