@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800&display=swap";
@import "https://fonts.googleapis.com/css2?family=Inter:wght@300;400;600;900&display=swap";
.home {
  text-align: left;
  background: #fff;
  border-radius: 10px;
  margin: auto;
  padding: 50px;
  font-size: 1.2rem;
}

@media (width <= 700px) {
  .home {
    padding: 10px;
  }
}

.home h1 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 2rem;
}

.landing {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: auto;
  display: flex;
}

.timeline-component {
  font-family: Inter, Lato, Helvetica, sans-serif;
}

.timeline-component iframe {
  border-radius: 20px;
  margin: 15px auto;
  box-shadow: 0 0 30px #0000001a;
}

.timeline-component .spotlight {
  opacity: 1;
}

.timeline-component p {
  opacity: .5;
  margin: 0;
  padding: 12px 15px;
  font-size: 1.3rem;
  font-weight: 400;
  display: block;
}

.timeline-component p a {
  text-transform: capitalize;
  color: #000;
  margin-right: 5px;
  text-decoration: none;
}

@media (width <= 700px) {
  .timeline-component p {
    padding-left: 5px;
    font-size: 1.2rem;
  }
}

.timeline-component p.spotlight, .timeline-component p:hover, .timeline-component p:active, .timeline-component p:focus {
  opacity: 1;
  transition: all .1s ease-in-out;
}

.timeline-component p.spotlight b, .timeline-component p:hover b, .timeline-component p:active b, .timeline-component p:focus b {
  letter-spacing: .3px;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(60deg, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  -webkit-background-clip: text;
  border-radius: 3px;
  padding-left: 3px;
  padding-right: 0;
  font-weight: 700;
  transition: all 1s ease-in-out;
  display: inline-block;
}

.timeline-component p.spotlight .date, .timeline-component p:hover .date, .timeline-component p:active .date, .timeline-component p:focus .date {
  opacity: 1;
  font-style: italic;
}

.timeline-component p .date {
  float: right;
  opacity: .5;
  font-size: 60%;
}

.timeline-component p b:hover {
  cursor: pointer;
}

.timeline-component p b:active {
  opacity: .8;
  transform: scale(.9);
}

.timeline-component p.move {
  text-align: right;
  opacity: .5;
  letter-spacing: 1.1px;
  text-transform: uppercase;
  padding-top: 10px;
  font-size: .85rem;
  font-weight: 500;
}

.timeline-component p.move:hover {
  transform: translateX(0);
}

.link-component {
  text-align: center;
  max-width: 500px;
  margin: 0 auto;
  display: block;
}

.link-component a {
  color: #000c;
  text-decoration: none !important;
}

.link-component .link {
  text-align: center;
  color: #000;
  background: #00000008;
  border: 3px solid #00000008;
  border-radius: 20px;
  width: 50px;
  height: 35px;
  margin: 10px;
  padding: 6px 12px;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 35px;
  display: inline-block;
}

.link-component .link:hover {
  border: 3px solid #00000014;
  height: 34px;
  font-size: 1.4rem;
  line-height: 35px;
  transition: all ease-in-out;
  box-shadow: 0 0 10px #0000001a;
}

.link-component .link:active {
  opacity: .8;
  background: #fff;
  border-color: #0000004d;
}

@media (width <= 700px) {
  .link-component .link {
    width: 40px;
    margin: 2px 3px;
    padding-top: 5px;
    padding-bottom: 5px;
    font-size: 1.3rem;
  }
}

.recent-component {
  text-align: center;
  color: #000;
  opacity: .9;
  border-radius: 10px;
  margin: 0 5px;
  padding: 30px;
  font-size: 1.2rem;
  font-weight: 400;
}

.recent-component .title-component {
  color: #fff !important;
}

.recent-component .list {
  line-height: 2rem;
}

.recent-component a {
  color: #000;
  font-weight: 800;
  text-decoration: none;
}

.recent-component a:hover {
  font-style: italic;
}

.works-component .card-container {
  flex-direction: row;
  width: 100%;
  margin: 10px auto;
  display: flex;
}

.works-component .card-container .card-item {
  cursor: pointer;
  background: #9f91911a;
  border-radius: 50px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 70px;
  margin: 5px;
  font-weight: 900;
  transition: all .1s ease-in-out;
  display: flex;
}

@media (width <= 700px) {
  .works-component .card-container .card-item {
    border-radius: 15px;
    margin: 2px;
    font-size: 1rem;
  }
}

.works-component .card-container .card-item:hover .title {
  opacity: .8;
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  -webkit-background-clip: text;
}

.fav-display .fav-module {
  margin-top: 30px;
  margin-bottom: 30px;
}

.fav-display .fav-module .fav-module-title {
  text-align: center;
  text-transform: capitalize;
  opacity: .8;
  font-weight: 400;
}

.fav-display .display-container {
  -webkit-overflow-scrolling: touch;
  border: none;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 20px;
  display: flex;
  overflow-y: hidden;
}

.fav-display .display-container:hover::-webkit-scrollbar {
  display: block;
}

.fav-display .display-container::-webkit-scrollbar {
  width: 0;
  height: 0;
  display: none;
}

.fav-display .display-container::-webkit-scrollbar-track {
  background: #00000003;
}

.fav-display .display-container::-webkit-scrollbar-thumb {
  background: #0000001a;
}

.fav-display .display-container::-webkit-scrollbar-thumb:hover {
  cursor: pointer;
  background: #555;
}

.fav-display .display-container.products .display-item .card .frame, .fav-display .display-container.events .display-item .card .frame, .fav-display .display-container.blockchain-projects .display-item .card .frame {
  box-shadow: none;
  border-radius: 100px;
}

.fav-display .display-container.products .display-item .card .frame:hover, .fav-display .display-container.events .display-item .card .frame:hover, .fav-display .display-container.blockchain-projects .display-item .card .frame:hover {
  border-radius: 100px;
  transition: all .1s cubic-bezier(.075, .82, .165, 1);
  transform: translateY(-3px);
  box-shadow: 0 0 20px #0000;
}

.fav-display .display-container.products .display-item .card .frame:active, .fav-display .display-container.events .display-item .card .frame:active, .fav-display .display-container.blockchain-projects .display-item .card .frame:active {
  border-radius: 100px;
  transform: scale(.97);
  box-shadow: 0 0 20px #0000;
}

.fav-display .display-container.products .display-item .card .frame img, .fav-display .display-container.events .display-item .card .frame img, .fav-display .display-container.blockchain-projects .display-item .card .frame img {
  border-radius: 100px;
}

.fav-display .display-container.products .display-item .card .name, .fav-display .display-container.products .display-item .card .notes, .fav-display .display-container.events .display-item .card .name, .fav-display .display-container.events .display-item .card .notes, .fav-display .display-container.blockchain-projects .display-item .card .name, .fav-display .display-container.blockchain-projects .display-item .card .notes {
  text-align: center;
}

.fav-display .display-container .display-item {
  text-align: center;
  cursor: pointer;
}

.fav-display .display-container .display-item:last-child {
  padding-right: 20px;
}

.fav-display .display-container .display-item.pin-false {
  opacity: .1;
}

.fav-display .display-container .display-item.pin-true {
  opacity: 1;
}

.fav-display .display-container .display-item .card {
  margin: 10px;
}

.fav-display .display-container .display-item .card .frame {
  border-radius: 1.5rem;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 0 2px #0000001a;
}

.fav-display .display-container .display-item .card .frame img {
  text-align: center;
  width: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.fav-display .display-container .display-item .card .name, .fav-display .display-container .display-item .card .notes {
  opacity: .6;
  text-align: center;
  overflow-wrap: break-word;
  text-transform: capitalize;
  margin: 0;
  font-size: .8rem;
  display: block;
}

.fav-display .display-container .display-item .card .notes {
  text-transform: lowercase;
  opacity: .3;
  font-size: .7rem;
}

.fav-display .display-container .display-item:hover .name {
  opacity: 1;
}

.component.footer {
  text-align: center;
  opacity: .5;
  margin: auto;
  padding-top: 50px;
  font-size: .7rem;
  font-weight: 600;
}

.component.footer .signature {
  opacity: 1;
  margin-top: 10px;
  font-size: .8rem;
}

.component.branding {
  text-align: center;
  margin-top: 50px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}

.component.branding .name {
  text-align: center;
  opacity: 1;
  font-size: 8rem;
  font-weight: 500;
}

@media (width <= 750px) {
  .component.branding .name {
    font-size: 3.3rem;
  }
}

@media (width <= 810px) and (width >= 700px) {
  .component.branding .name {
    font-size: 6rem;
  }
}

.component.branding .real-name {
  font-family: Inter, Lato, Helvetica, sans-serif;
  font-size: 1.2rem;
  font-weight: 900;
}

.fav-display .display-drawer {
  z-index: 999;
  backdrop-filter: blur(20px);
  text-align: center;
  background: #ffffff4d;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  width: 300px;
  height: 500px;
  padding: 20px;
  transition: position .2s ease-in-out;
  display: block;
  position: fixed;
  top: 0;
  overflow-y: scroll;
  box-shadow: 0 0 30px -15px #0000004d;
}

.fav-display .display-drawer img {
  border: 2px solid #0000001a;
  border-radius: 10px;
  width: 60%;
  max-width: 200px;
}

.fav-display .display-drawer .check-it {
  cursor: pointer;
  text-transform: capitalize;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  color: #fff;
  background: #000;
  border-radius: 10px;
  margin: 20px 20px 50px;
  padding: 8px 30px;
  font-size: 1rem;
  font-weight: 600;
  display: inline-block;
}

.fav-display .display-drawer .check-it:hover {
  background: #000c;
}

.fav-display .display-drawer .check-it:active {
  background: #000000b3;
}

.fav-display .display-drawer .intro {
  opacity: .8;
  text-align: center;
  margin: 0;
  padding: 10px;
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 2rem;
}

.fav-display .display-drawer .name {
  text-align: center;
  text-transform: capitalize;
  color: #8d6708;
  margin-bottom: 20px;
  font-size: 2rem;
  font-weight: 900;
}

.fav-display .display-drawer .frame {
  border-radius: 10px;
  width: 50px;
  height: 50px;
  position: relative;
  overflow: hidden;
}

.fav-display .display-drawer .frame img {
  text-align: center;
  height: 100%;
  margin: auto;
  position: absolute;
  inset: 0;
}

.fav-display .display-drawer.pin-true {
  box-shadow: 0 0 40px 10px #0000001a;
}

.fav-display .display-drawer.false {
  transition: all .2s ease-in-out;
  bottom: -500px;
  left: 0;
  right: 0;
}

.fav-display .display-drawer.true {
  transition: all .2s ease-in-out;
  bottom: 0;
  left: 0;
  right: 0;
}

html {
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: none;
  font-size: 110%;
}

#id {
  margin: 0;
}

body {
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, Lato, Helvetica, sans-serif;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.gradient-txt {
  -webkit-text-fill-color: transparent;
  background: -webkit-linear-gradient(-30deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8);
  -webkit-background-clip: text;
}

.component {
  max-width: 1000px;
}

.component .title-component {
  text-transform: uppercase;
  text-align: center;
  letter-spacing: .5rem;
  margin: 20px;
  font-size: 1rem;
  font-weight: 700;
}

.component > .title {
  text-transform: uppercase;
  letter-spacing: .5px;
  font-size: 1.6rem;
  font-weight: 900;
}

.component .topic, .component .hackathon {
  padding: 5px 10px 10px;
  transition: all .2s ease-in-out;
  position: relative;
  box-shadow: -5px 0 #0000001a;
}

.component .topic .date, .component .topic .info, .component .hackathon .date, .component .hackathon .info {
  opacity: .7;
  font-size: .8rem;
}

.component .topic .date i, .component .topic .info i, .component .hackathon .date i, .component .hackathon .info i {
  width: 13px;
  margin-left: 5px;
  margin-right: 0;
  font-size: .8rem;
  display: inline-block;
}

.component .topic .exactData, .component .hackathon .exactData {
  padding-left: 5px;
  font-size: .8rem;
  font-style: italic;
  display: none;
}

.component .topic.future:hover .light, .component .hackathon.future:hover .light {
  background: #00000080;
}

.component .topic .light, .component .hackathon .light {
  opacity: 0;
  border-radius: 10px;
  width: 8px;
  height: 8px;
  margin-left: 5px;
  transition: all .3s ease-in-out .3s;
  display: none;
}

.component .topic .date-tag, .component .hackathon .date-tag {
  display: none;
  position: absolute;
  top: 3px;
  right: 10px;
}

.component .topic:hover, .component .topic:active, .component .hackathon:hover, .component .hackathon:active {
  background: #00ced11a;
  padding-top: 20px;
  padding-bottom: 20px;
  transition: all .2s ease-in-out;
  transform: translate(-5px)scale(1.01);
  box-shadow: -5px 0 0 -.01px #00ced1;
}

.component .topic:hover .date-tag, .component .topic:active .date-tag, .component .hackathon:hover .date-tag, .component .hackathon:active .date-tag {
  display: block;
}

.component .topic:hover .date-tag .date, .component .topic:active .date-tag .date, .component .hackathon:hover .date-tag .date, .component .hackathon:active .date-tag .date {
  color: #00b5b8;
}

.component .topic:hover .light, .component .topic:active .light, .component .hackathon:hover .light, .component .hackathon:active .light {
  opacity: 1;
  background: #00ced1;
  transition: all .3s ease-in-out;
  display: inline-block;
}

.component .topic:hover.future, .component .topic:active.future, .component .hackathon:hover.future, .component .hackathon:active.future {
  background: #00000008;
  box-shadow: -5px 0 0 -.01px #00000080;
}

.component .topic:hover.future .date, .component .topic:active.future .date, .component .hackathon:hover.future .date, .component .hackathon:active.future .date {
  color: #00000080;
}

.component .topic:hover .exactData, .component .topic:active .exactData, .component .hackathon:hover .exactData, .component .hackathon:active .exactData {
  display: inline-block;
}

.component .topic > .title, .component .hackathon > .title {
  font-weight: 600;
}

.component .topic .comment, .component .hackathon .comment {
  padding-left: 5px;
}

.component .topic .resourceTitle, .component .hackathon .resourceTitle {
  opacity: .8;
  margin-top: 5px;
  font-size: .8rem;
}

.component .topic a.resource, .component .topic .project, .component .hackathon a.resource, .component .hackathon .project {
  color: #00000080;
  margin-top: 5px;
  padding: 10px 15px;
  text-decoration: none;
  display: block;
  box-shadow: inset 0 0 0 1px #0000001a;
}

.component .topic a.resource + .resource, .component .topic .project + .resource, .component .hackathon a.resource + .resource, .component .hackathon .project + .resource {
  margin-top: 8px;
}

.component .topic a.resource .title, .component .topic .project .title, .component .hackathon a.resource .title, .component .hackathon .project .title {
  color: #000000b3;
}

.component .topic a.resource .instructorResource, .component .topic .project .instructorResource, .component .hackathon a.resource .instructorResource, .component .hackathon .project .instructorResource {
  font-size: .9rem;
}

.component .topic a.resource:hover, .component .topic .project:hover, .component .hackathon a.resource:hover, .component .hackathon .project:hover {
  text-decoration: none;
  box-shadow: inset 0 0 0 .8px #0000004d;
}

.component .topic a.resource .prize, .component .topic .project .prize, .component .hackathon a.resource .prize, .component .hackathon .project .prize {
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.component .topic a.resource .prize:hover .left, .component .topic .project .prize:hover .left, .component .hackathon a.resource .prize:hover .left, .component .hackathon .project .prize:hover .left {
  font-size: 1.6rem;
}

.component .topic a.resource .prize .left, .component .topic .project .prize .left, .component .hackathon a.resource .prize .left, .component .hackathon .project .prize .left {
  text-align: center;
  color: #8d6708;
  border-radius: .275rem;
  width: 50px;
  height: 50px;
  margin: 10px 10px 10px 0;
  font-size: 1.5rem;
  line-height: 50px;
  box-shadow: 0 0 0 1.2px #8d6708;
}

.component .topic a.resource .prize + .prize, .component .topic .project .prize + .prize, .component .hackathon a.resource .prize + .prize, .component .hackathon .project .prize + .prize {
  opacity: .85;
}

.component .topic a.resource .prize + .prize + .prize, .component .topic .project .prize + .prize + .prize, .component .hackathon a.resource .prize + .prize + .prize, .component .hackathon .project .prize + .prize + .prize {
  opacity: .7;
}

.component .map {
  width: 100vw;
  height: 500px;
  margin-left: -30px;
}

.component .pin {
  position: relative;
}

.component .pin .point {
  background: #00ced1;
  border-radius: 20px;
  width: 10px;
  height: 10px;
  position: absolute;
  bottom: 0;
  left: 0;
}

.component .pin .point:hover {
  background: lignten(#00ced1, 30%);
  opacity: 1;
  z-index: 99;
  transform: scale(1.5);
}

.component .pin .mapTag {
  z-index: 99;
  display: none;
}

.component .pin .mapTag .time {
  opacity: .8;
  margin-bottom: 1px;
  font-size: .4rem;
}

.component .pin:hover .mapTag {
  border-radius: .1rem;
  width: 150px;
  height: 45px;
  padding: 5px;
  font-size: .7rem;
  display: inline-block;
  position: absolute;
  bottom: 20px;
  left: 20px;
  overflow: hidden scroll;
  transform: scale(1.2);
  box-shadow: 1px 1px 10px #0003;
}

.component .pin:hover .mapTag .time {
  font-size: .6rem;
}

.component .pin:hover .mapTag i {
  margin-right: 5px;
}

.component .lead {
  text-transform: capitalize;
  margin-bottom: 10px;
}

.component .lead .learn {
  text-transform: uppercase;
  background: #00ced11a;
  margin: 0 5px 0 15px;
  padding: 0 5px;
  font-size: .7rem;
  display: inline-block;
  box-shadow: -5px 0 #00ced1;
}

.component .lead .willLearn {
  text-transform: uppercase;
  background: #00000008;
  margin: 0 5px 0 10px;
  padding: 0 5px;
  font-size: .7rem;
  display: inline-block;
  box-shadow: -5px 0 #00000080;
}

.component .lead .smallBtn {
  cursor: pointer;
  text-transform: uppercase;
  letter-spacing: .7px;
  opacity: .5;
  border-radius: .2rem;
  margin-top: 5px;
  padding: 4px 13px;
  font-size: .6rem;
  display: inline-block;
  box-shadow: inset 0 0 0 2px #0000001a;
}

.component .lead .smallBtn + .smallBtn {
  margin-left: 10px;
}

.component .lead .smallBtn:hover {
  opacity: .8;
  box-shadow: inset 0 0 0 1.5px #0000004d;
}

.component .lead .smallBtn:active {
  transform: scale(.9);
}
/*# sourceMappingURL=index.3f63c733.css.map */
