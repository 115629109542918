.component{
    max-width: 1000px;

    .title-component {
        text-transform: uppercase;
        text-align: center;
        letter-spacing: 0.5rem;
        font-weight: 700;
        margin: 20px;
        font-size: 1rem;
        // color: $gold;
    }
    
    &>.title{
        text-transform: uppercase;
        letter-spacing: 0.5px;
        font-size: 1.6rem;
        font-weight: 900;
    }
        
    .topic, .hackathon{
        position: relative;
        padding: 5px 10px 10px 10px;
        box-shadow: -5px 0px 0px rgba(0,0,0,0.1);
        transition: .2s all ease-in-out;

        .date, .info{
            opacity: 0.7;
            font-size: 0.8rem;

            i{
                margin-left: 5px;
                font-size: 0.8rem;
                display: inline-block;
                width: 13px;
                margin-right: 0px;
            }
        }
        .exactData{
            font-size: 0.8rem;
            padding-left: 5px;
            display: none;
            font-style: italic;
        }
        &.future:hover{
            .light {
                background: rgba(0,0,0,0.5);
            }
        }
        .light{
            display: none;
            opacity: 0;
            width: 8px;
            height: 8px;
            border-radius: 10px;
            transition: .3s all ease-in-out;
            transition-delay: .3s;
            margin-left: 5px;
        }
        .date-tag{
            display: none;
            position: absolute;
            top: 3px;
            right: 10px;
        }
        
        &:hover, &:active{
            transition: .2s all ease-in-out;
            background:rgba($primary, 0.1);
            box-shadow: -5px 0px 0px -0.01px $primary;
            transform: translate(-5px, 0px) scale(1.01);
            padding-top: 20px;
            padding-bottom: 20px;
            .date-tag{
                display: block;
                .date{
                    color: darken($primary, 5%);
                }
            }
            .light {
                background: $primary;
                display: inline-block;
                opacity: 1;
                transition: .3s all ease-in-out;
            }

            &.future{
                .date{
                    color: rgba(0,0,0,0.5);
                }
                background:rgba(0,0,0,0.03);
                box-shadow: -5px 0px 0px -0.01px rgba(0,0,0,0.5);
            }
            .exactData {
                display: inline-block;
            }
        }

        &>.title {
            font-weight: 600;
        }

        .comment {
            padding-left: 5px;
        }

        .resourceTitle{
            margin-top: 5px;
            font-size: 0.8rem;
            opacity: 0.8;
        }

        a.resource, .project{
            display: block;
            margin-top: 5px;
            padding: 10px 15px;
            box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.1);
            color: rgba(0,0,0,0.5);
            text-decoration: none;
            &+.resource{
                margin-top: 8px;
            }
            .title{
                color: rgba(0,0,0,0.7);
            }
            .instructorResource{
                font-size: 0.9rem;
            }

            &:hover{
                text-decoration: none;
                box-shadow: inset 0px 0px 0px 0.8px rgba(0,0,0,0.3);
            }

            .prize{
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                &:hover{
                    .left{
                        // background: $gold;
                        // color: white;
                        font-size: 1.6rem;
                    }
                }

                .left{
                    height: 50px;
                    width: 50px;
                    font-size: 1.5rem;
                    text-align: center;
                    line-height: 50px;
                    margin: 10px 10px 10px 0px;
                    border-radius: 0.275rem;
                    color: $gold;
                    // border: 1px solid $gold;
                    box-shadow: 0px 0px 0px 1.2px $gold;
                }
                &+.prize{
                    opacity: 0.85
                }
                &+.prize+.prize{
                    opacity: 0.7
                }
            }
        }
    }

    .map{
        width: 100vw;
        margin-left: -30px;
        height: 500px;
    }
    .pin{
        position: relative;
        
        .point{
            border-radius: 20px;
            background: $primary;
            width: 10px;
            height: 10px;
            position: absolute;
            bottom: 0px;
            left: 0px;
            &:hover{
                background: lignten($primary, 30%);
                opacity: 1;
                transform: scale(1.5);
                z-index: 99;
            }
        }
        .mapTag{
            display: none;
            z-index: 99;
            .time{
                opacity: 0.8;
                font-size: 0.4rem;
                margin-bottom: 1px;
            }
        }
        &:hover{
            .mapTag {
                transform: scale(1.2);
                display: inline-block;
                position: absolute;
                bottom: 20px;
                left: 20px;
                height: 45px;
                overflow-y: scroll;
                overflow-x: hidden;
                width: 150px;
                padding: 5px;
                font-size: 0.7rem;
                border-radius: 0.1rem;
                box-shadow: 1px 1px 10px rgba(0,0,0,0.2);
            
                .time{
                    font-size: 0.6rem;
                }

                i{
                    margin-right: 5px;
                }
            }
        }
        
    }
    
    
    .lead{
        text-transform: capitalize;
        margin-bottom: 10px;
        .learn{
            font-size: 0.7rem;
            background:rgba($primary, 0.1);
            box-shadow: -5px 0px 0px 0px $primary;
            margin: 0px 5px 0px 15px;
            padding: 0px 5px;
            display: inline-block;
            text-transform: uppercase;
        }
        .willLearn{
            text-transform: uppercase;
            font-size: 0.7rem;
            background:rgba(0,0,0,0.03);
            box-shadow: -5px 0px 0px 0px rgba(0,0,0,0.5);
            margin: 0px 5px 0px 10px;
            padding: 0px 5px;
            display: inline-block;
        }
        .smallBtn{
            font-size: 0.6rem;
            cursor: pointer;
            text-transform: uppercase;
            letter-spacing: 0.7px;
            opacity: 0.5;
            box-shadow: inset 0px 0px 0px 2px rgba(0,0,0,0.1);
            display: inline-block;
            padding: 4px 13px;
            border-radius: 0.2rem;
            display: inline-block;
            margin-top: 5px;
            &+.smallBtn{
                margin-left: 10px;
            }
            &:hover{
                box-shadow: inset 0px 0px 0px 1.5px rgba(0,0,0,0.3);
                opacity: 0.8;
            }

            &:active{
                transform: scale(0.9);
            }
        }
    }
}