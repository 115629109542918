@import './variable';
@import './display-drawer.scss';

html{
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: none;
    font-size: 110%;
}

#id {
    margin: 0px 0px;
}

body {
    user-select: none;
    overflow-x: hidden;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-family: $sans;
    // background: -webkit-linear-gradient(0deg,#21b8a4,#70c084)
    // used for debug
    // div{
    //     outline: 1px solid rgba(black, 0.05) !important;
    // }
}

a {
    text-decoration: none;
    color: inherit;
}

.gradient-txt {
    // background: -webkit-linear-gradient(-30deg,#f79533,#f37055,#ef4e7b,#07b39b,#6fba82);
    // background: -webkit-linear-gradient(-30deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
    background: -webkit-linear-gradient(-30deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
    

@import './component';