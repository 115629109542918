.component.footer {
    text-align: center;
    margin: auto;
    padding-top: 50px;
    font-weight: 600;
    font-size: 0.7rem;
    opacity: 0.5;
    .signature {
        font-size: 0.8rem;
        opacity: 1;
        margin-top: 10px;
    }
}