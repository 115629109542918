
$display-item-size: 125px;

.fav-display{
    .fav-module {
        margin-top: 30px;
        margin-bottom: 30px;
        .fav-module-title {
            text-align: center;
            text-transform: capitalize;
            font-weight: 400;
            opacity: 0.8;
        }
    }
    .display-container {
        width: 100%;
        border: none;
        overflow-y: hidden;
        display: flex;
        flex-direction: row;
        justify-content: center;
        -webkit-overflow-scrolling: touch;
        padding-top: 10px;
        padding-bottom: 20px;

        &:hover::-webkit-scrollbar {
            display: block;
        }

        &::-webkit-scrollbar {
            display: none;
            height: 0px;
            width: 0px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba(0,0,0,0.01);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: rgba(0,0,0,0.1);
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #555;
            cursor: pointer;
        }

        &.products, &.events, &.blockchain-projects {            .display-item{
                .card {
                    .frame {
                        border-radius: 100px;
                        box-shadow: none;
                        &:hover{
                            transition: .1s all cubic-bezier(0.075, 0.82, 0.165, 1);
                            transform: translateY(-3px);
                            border-radius: 100px;
                            box-shadow: 0px 0px 20px rgba(0,0,0,0);
                        }
                        &:active{
                            border-radius: 100px;
                            transform: scale(0.97);
                            box-shadow: 0px 0px 20px rgba(0,0,0,0);
                        }
                        img {
                            border-radius: 100px;
                        }
                    }
                    .name, .notes {
                        text-align: center;
                    }
                }
            }
        }
        .display-item {
            text-align: center;
            cursor: pointer;

            &:last-child {
                padding-right: 20px;
            }
            &.pin-false{
                opacity: 0.1;
            }
            &.pin-true {
                opacity: 1;
            }
            .card {
                margin: 10px;
                .frame {
                    box-shadow: 0px 0px 0px 2px rgba(0,0,0,0.1);
                    position: relative;
                    overflow: hidden;
                    margin-bottom: 10px;
                    border-radius: 1.5rem;
                    //&:hover{
                    //    transition: .1s all cubic-bezier(0.075, 0.82, 0.165, 1);
                    //    transform: translateY(-3px);
                    //    box-shadow: 0px 5px 20px rgba(0,0,0,0.2);
                    //}
                    img {
                        position: absolute;
                        left: 0px;
                        top: 0px;
                        right: 0px;
                        width: 100%;
                        margin: auto;
                        text-align: center;
                    }
                }
                .name, .notes{
                    display: block;
                    opacity: 0.6;
                    font-size: 0.8rem;
                    text-align: center;
                    margin: 0px;
                    overflow-wrap: break-word;
                    text-transform: capitalize;
                }
                .notes {
                    text-transform: lowercase;
                    font-size: 0.7rem;
                    opacity: 0.3;
                }
            }
            &:hover{
                .name{
                    opacity: 1;
                }
            }
        }
    }
}