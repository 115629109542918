@import '../../../styles/variable.scss';

.timeline-component {
    font-family: $sans;
    iframe {
        border-radius: 20px;
        // border: 3px solid rgba(black, 0.1);
        box-shadow: 0px 0px 30px rgba(black, 0.1);
        margin: 15px auto;
    }
    .spotlight {
        opacity: 1;
    }
    p{
        font-size: 1.3rem;
        display: block;
        margin: 0px;
        padding: 12px 15px;
        font-weight: 400;
        opacity: 0.5;
        a {
            text-transform: capitalize;
            text-decoration: none;
            color: black;
            margin-right: 5px;
        }
        .highlight-link, b {
            // font-family: $roboto;
        }
        @media(max-width: 700px){
            font-size: 1.2rem;
            padding-left: 5px;
        }
        &.spotlight,&:hover,&:active,&:focus {
            opacity: 1;
            transition: .1s all ease-in-out;
            b {
                border-radius: 3px;
                display: inline-block;
                transition: 1s all ease-in-out;
                padding-right: 0px;
                padding-left: 3px;
                letter-spacing: 0.3px;
                font-weight: 700;
                background: -webkit-linear-gradient(60deg, #a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
            }
            .date {
                opacity: 1;
                font-style: italic;
            }
        }
        .date {
            float: right;
            font-size: 60%;
            opacity: 0.5;
        }
        b {
            &:hover{
                cursor: pointer;
            }
            &:active{
                opacity: 0.8;
                transform: scale(0.9);
            }
        }
        &.move {
            text-align: right;
            opacity: 0.5;
            font-size: 0.85rem;
            font-weight: 500;
            letter-spacing: 1.1px;
            padding-top: 10px;
            text-transform: uppercase;
            &:hover{
                transform: translateX(0px);
            }
        }
    }
}
