
.works-component{
    .card-container {
        margin: 10px auto;
        display: flex;
        flex-direction: row;
        width: 100%;

        .card-item {
            flex-grow: 1;
            display: flex;
            font-weight: 900;
            align-items: center;
            justify-content: center;
            
            cursor: pointer;
            border-radius: 50px;
            margin: 5px;
            transition: .1s all ease-in-out;
            background: rgba(159, 145, 145, 0.1);
            height: 70px;

            @media (max-width: 700px){
                border-radius: 15px;
                margin: 2px;
                font-size: 1rem;
            }

            &:hover{
                .title {
                    opacity: 0.8;
                    background: -webkit-linear-gradient(60deg,#f79533,#f37055,#ef4e7b,#a166ab,#5073b8,#1098ad,#07b39b,#6fba82);
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                }
            }
        }
    }
}
