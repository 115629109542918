@import '../../../styles/variable.scss'

.link-component
    max-width: 500px
    display: block
    margin: 0px auto
    text-align: center

    a
        text-decoration: none !important
        color: rgba(0,0,0,0.8)

    .link
        margin: 10px
        text-align: center
        color: rgba(0,0,0,1)
        border-radius: 20px
        background: rgba(black, 0.03)
        display: inline-block
        font-size: 1.6rem
        font-weight: 500
        padding: 6px 12px
        width: 50px
        height: 35px
        line-height: 35px
        border: 3px solid rgba(0,0,0,0.03)

        &:hover
            height: 34px
            line-height: 35px
            transition: 0s all ease-in-out
            font-size: 1.4rem
            border: 3px solid rgba(black, 0.08)
            box-shadow: 0px 0px 10px rgba(black, 0.1)

        &:active
            background: white
            opacity: 0.8
            border-color: rgba(0,0,0,0.3)

        @media(max-width: 700px)
            font-size: 1.3rem
            width: 40px
            margin: 2px 3px
            padding-top: 5px
            padding-bottom: 5px
